@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
*,html {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
  // background-color: #f5f8fa !important;
  min-height: 100vh !important;
  position: relative !important;
  padding-bottom: 53px;
}
.footer{
  position: absolute;
  bottom: 0
}
@media (max-width: 425px) {
  .sm-marginY {
    margin-top:1rem !important;
    margin-bottom:1rem !important; 
  }
  .sm-margintop {
    margin-top:1rem !important;
  }
  .sm-marginbottom {
    margin-bottom:1rem !important; 
  }
  .sm-paddingY {
    padding-top:2rem;
    padding-bottom:2rem;
  }
}
.chakra-ui-light{
  background-color: #f5f8fa !important;
}