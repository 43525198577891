@use "./Scss-vars/Colors.scss";

@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
.capitalize{
  text-transform: capitalize;
}
.light-gray {
  font-size: 13px;
  color: #9aa5a8;
  font-weight: 600;
}
.bg-light-gray {
  background-color: #dbdbdb;
}
.bg-light-primary {
  background-color: #3ba1d1;
}
.text-light-primary {
  color: #3ba1d1;
}
.bg-error {
  background-color: #d42c51;
}
.text-error {
  color: #d42c51 !important;
}
.dropWrap {
  .dropdown-menu {
    left: -100px;
    min-width: 7rem;
    padding: 0.25rem;
    .dropdown-item {
      padding: 5px;
      font-size: 13px;
      font-weight: bold;
    }
  }
}


.active-nav {
  color: #3ba1d1 !important;
  text-decoration: none;
  text-align: center;
  font-size: 15px;
  border-bottom: 1px solid #3ba1d1;
  .icon {
    background-color: yellow;
  }
  &:hover {
    color: #3ba1d1;
  }
}
.not-active-nav {
  transition: 0.3s ease-in-out;
  font-size: 15px;
  &:hover {
    color: #3ba1d1 !important;
    text-decoration: none;
    font-size: 15px;
    border-bottom: 1px solid #3ba1d1;
  }
}

.css-k7r2wc {
  height: 48px !important;
}
.css-1ipn0iq{
  height: 48px !important;
}
.css-1c6j008 {
  height: 48px !important;
}
.css-1c6j008[aria-invalid="true"] {
  border: 1px solid !important;
  border-color: inherit !important;
  box-shadow: none !important
;
}
.css-1c6j008:focus,
.css-1c6j008[data-focus] {
  z-index: 1;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}
.css-xpongc{
  height: 48px !important;
}
.css-xpongc[aria-invalid="true"] {
  border: 1px solid !important;
  border-color: inherit !important;
  box-shadow: none !important
;
}
.css-xpongc:focus,
.css-xpongc[data-focus] {
  z-index: 1;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}
.text-liGray {
  color: #b7bcbd;
}
.css-k7r2wc[aria-invalid="true"],
.css-k7r2wc[data-invalid] {
  border: 1px solid !important;
  border-color: inherit !important;
  box-shadow: none !important;
}
.rounded {
  border-radius: 10px !important;
}

.react-tel-input .flag-dropdown {
  z-index: 10;
}

.fw-600 {
  font-weight: 500;
  color: #979797;
  font-size: 15px;
}

.pending {
  color: Colors.$info;
  background-color: Colors.$info-opacity !important;
  padding: 0.6rem;
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  cursor:default;
  &:hover {
    background-color: rgba(255, 189, 40, 0.2);
  }
}
.done{
  color: Colors.$primary;
  background-color: Colors.$primary-opacity;
  padding: 0.6rem;
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  cursor:default;
  &:hover {
    background-color: Colors.$primary-opacity-hover;
  }
}
.done-dark{
  color: Colors.$secondary;
  background-color: Colors.$secondary-opacity;
  &:hover {
    background-color: Colors.$secondary-opacity-hover;
  }
}

.Warning{
  color: Colors.$Warning;
  background-color: Colors.$Warning-opacity;
  padding: 0.6rem;
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  cursor:default;
  &:hover {
    background-color: Colors.$Warning-opacity-hover;
  }
}

.input-search{
  position: relative;
  input{
      position:static !important
  };
  .icon-search{ 
      font-size: 20px;
      position: absolute;
      top: 25%;
      left: 6%;
  }
}

@media (min-width: 1200px){
.container {
    max-width: 1050px;}
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #21118D !important;
}
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: rgba(33,17,141,.8) !important;
}
[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #21118D !important;
}
[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: rgba(33,17,141,.8) !important;
}
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  background-color: #21118D !important;
  cursor: not-allowed;
}
[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  background-color: rgba(33,17,141,.8) !important;
}

.css-1wp7f55{
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding: 0;}
  .icons{
    fill: red;
  }
  .helperText{
    position: relative;
    span{
      position: absolute;
      top: 25%;
      right: 3%;
      font-size: 13px;
      font-weight: bold;
      color: gray;
    }
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(.6);
    cursor: pointer;
}

.css-114gsui {
    cursor: pointer;
     z-index: 0  !important;
}
.input-drop-down-phone .country span{
  position: static !important;
}
.input-drop-down-dark .country{
  background-color: transparent !important;

}
.input-drop-down-dark .country:hover{
  background-color: rgb(181, 181, 181) !important;
}
.input-drop-down-button-dark :hover{
  background-color: rgb(72, 72, 72) !important;
}
.input-drop-down-search{
  z-index: 50;
}
.flatpickr-calendar  {
  .flatpickr-month{
    background-color: #21118D !important;
    .flatpickr-monthDropdown-months{
      background-color: #21118D !important;
      .flatpickr-monthDropdown-month{
        background-color: #21118D !important;
      }
    }
  }
  .flatpickr-innerContainer{
    .flatpickr-rContainer{
      .flatpickr-weekdays{
        background-color: #21118D;
        .flatpickr-weekdaycontainer{
          .flatpickr-weekday{
            background-color: #21118D;
            color: #ffffff;
          }
        }
      }
      .flatpickr-days{
        .dayContainer{
          .selected{
            background-color: #21118D;
          }
        }
      }
    }
  }
}