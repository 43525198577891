@use "../../Scss-vars/Colors";
.menu-condition{
    min-width: 150px !important;
}

button:focus:not(:focus-visible) {
    outline: 0;
}
.css-taj3dd:focus, .css-taj3dd[data-focus] {
    box-shadow: none !important; 
}
.midical-nav{
    color: Colors.$smallTextGray;
    transition: 0.2s ease-in-out;
    &:hover {
        color: Colors.$primary;
        border-bottom: 1.2px solid Colors.$primary;
        .circle{
            background-color: Colors.$primary;
        }
      }
}
.midical-nav-dark{
    &:hover {
        color: Colors.$secondary;
        border-bottom: 1.2px solid Colors.$secondary;
        .circle{
            background-color: Colors.$secondary;
        }
      }
}

.midical-nav-small{
    color: Colors.$smallTextGray;
    transition: 0.2s ease-in-out;
    &:hover {
        color: Colors.$white;
        background-color: Colors.$primary;
      }
}
.midical-nav-small-dark{
    &:hover {
        color: Colors.$white;
        background-color: Colors.$secondary;
      }
}
.midical-active{
    color: Colors.$primary !important;
    border-bottom: 1.2px solid Colors.$primary;
    div{
        background-color: Colors.$primary;
    }
}
.midical-active-dark{
    color: Colors.$secondary !important;
    border-bottom: 1.2px solid Colors.$secondary;
    div{
        background-color: Colors.$secondary;
    }
}
.midical-active-small{
    color: Colors.$white !important;
    border-bottom: 1.2px solid Colors.$primary;
    background-color: Colors.$primary;
    div{
        background-color: Colors.$primary;
    }
}
.midical-active-small-dark{
    color: Colors.$white !important;
    border-bottom: 1.2px solid Colors.$secondary;
    background-color: Colors.$secondary;
    div{
        background-color: Colors.$secondary;
    }
}
.css-ck9wzx[data-active] {
    background: #d9e6ec !important;
    color: Colors.$primary;
}

.medical-small-wrap{
    max-height:150px ;
    overflow-y: auto;
}
@media only screen and (max-width: 425px) {
    .add-wrap{
        width: 100% !important;
    }
  }

  .pdf-download-wrap{
    transition: .3s ease-in-out;    
    &:hover{
        .pdf-download{
            opacity: .9;  
        }
    }
}

  .pdf-download{
    transition: .3s ease-in-out;
    opacity: .5;  
  }

  .uppy-dark .uppy-Dashboard-inner{
    background-color: #1B2D3E ;
  }
  .uppy-dark .uppy-Dashboard-AddFiles-title{
    color: white ;
  }
 