$lightGray: #8f9698;
$lighterGray: #bec2c3;
$mainColor:#3ba1d1;
 



$app-background:#D8D8D8; 
$mainText:#000;
$secondary:#30B8BA;
$secondary-opacity:rgba(48, 184, 186, .15);
$secondary-opacity-hover:rgba(48, 184, 186, .30);
$error:#D42C51;
$primary:#21118D;
$primary-hover: rgba(33,17,141,.8);
$primary-opacity:rgba(33,17,141,.15);
$primary-opacity-hover:rgba(33,17,141,.30);
$smallTextGray:#8F9698;
$info:#FFB100;
$info-opacity:rgba(255, 189, 40, 0.1);
$white:#fff;

$transition: .2s ease-in-out;
$Warning:#CC3300;
$Warning-opacity:rgba(204 , 51, 0, 0.1);
$Warning-opacity-hover:rgba(204 , 51, 0, 0.30)