@use "../../Scss-vars/Colors.scss";

.login-row {
  padding: 30px 60px;
  @media only screen and (max-width: 600px){
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.forget-password {
  color: Colors.$primary;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}
.forget-password:hover {
  color: Colors.$primary-hover;
}
