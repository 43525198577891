.login-label {
  font-size: 1rem;
  margin-top: 0.5rem;
  font-weight: 500
}
.login-input {
  margin-top: 0.5rem;
  padding: 13px;
  outline: none;
  border: 1px solid rgb(218, 206, 206);
  font-size: 14px;
  border-radius: 1.5rem
}
.password-wrap {
  position: relative;
}
.aye {
  position: absolute;
  top: 53%;
  right: 5%;
  cursor: pointer;
  font-size: 16px;
  color: gray;
}
.errorMsg{
  font-size: 0.875rem
}
