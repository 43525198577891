@use "../../Scss-vars/Colors.scss";
.profile {
  .row {
    .avatar {
      position: relative;
      .camera {
        position: absolute;
        bottom: 0;
        right: 0;
        font-size: 12px;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        cursor: pointer;
      }
    }
    .list-wrab {
      width: 80%;
      .list {
        list-style: none;
        .list-item {
          a {
            transition: 0.2s ease-in-out;
            color: #979797;
            &:hover {
              color: Colors.$primary!important;
              background-color: Colors.$primary-opacity;
              border-left: solid 0.16rem Colors.$primary;
            }
          }
        }
        .list-item-dark{
        a{
          &:hover {
            color: Colors.$secondary!important;
            background-color: Colors.$secondary-opacity;
            border-left: solid 0.16rem Colors.$secondary;
          }
        }
        }
      }
    }
 
    .active-profile-link {
      color: Colors.$primary !important;
      background-color: Colors.$primary-opacity;
      border-left: solid 0.16rem Colors.$primary;
    }
    .active-profile-link-dark {
      color: Colors.$secondary !important;
      background-color: Colors.$secondary-opacity;
      border-left: solid 0.16rem Colors.$secondary;
    }
    .css-ddil6j[data-checked] {
      background: Colors.$primary !important;
    }
    .shareData{
      .css-ocimsn {
        min-width: 250px !important;
        border-color: Colors.$primary !important;
        font-size: 14px;
        &:focus {
          border-color: Colors.$primary !important;
        }
      }
    }
    .copy-btn {
      right: 30%;
      background-color: Colors.$primary-opacity;
      color: Colors.$primary;
    }
    label{
      font-size: 13px;
      color: #979797;
      font-weight: 500;
    }

    select{
      border-color: #979797;
      font-size: 14px;
      font-weight: 500;
    }
    .country{
      position: relative;
      span{
        content: "<i class='fas fa-angle-down'></i>";
        position: absolute;
        top: 25%;
        right: 5%;
        font-size: 15px;
        padding: 0;
      }
    }
  }

}





