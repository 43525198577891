@use "../../../../../Scss-vars/Colors.scss";
.success-check{
// #3C8F7C
    font-size: 13px;
    padding: .20rem;
    width: 70px;
    border:1px solid Colors.$lighterGray;
    border-radius: 60px;
    text-align: center;
    color: #3C8F7C;
    transition: ease-in-out .3s;
    cursor: pointer;
    i{
        font-size: 17px;
        margin-right: .5rem;
        font-weight: bold;
    }
    &:hover{
        background-color: #3C8F7C;
        color: #fff;
        
    }

}

.danger-check{
    // #3C8F7C
        font-size: 13px;
        padding: .20rem;
        width: 70px;
        border:1px solid Colors.$error;
        border-radius: 60px;
        text-align: center;
        color: Colors.$error;
        transition: ease-in-out .3s;
        cursor: pointer;
        i{
            font-size: 17px;
            margin-right: .5rem;
            font-weight: bold;
        }
        &:hover{
            background-color: Colors.$error;
            color: #fff;
            
        }

    }
    // .chakra-modal__overlay .css-hdd9l7{
    //     z-index: 0 !important;
    // }
    .MuiPopover-root{
        z-index: 1500 !important;
    }
    .error{
        color: red;
        font-size: 14px;
        transition: ease-in-out .3s;
    }
   


    .MuiFormControl-root{
        border-color: red !important;
    }