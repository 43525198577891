@use "../../Scss-vars/Colors.scss";
.Consultations{
    .consultation-sideNav {
        height: calc(100vh + 86px);
        // height: 100vh ;
        .consultation_nav {
            color: Colors.$smallTextGray;
            padding: .75rem;
            &:hover{
                background-color:Colors.$primary-opacity ;
                color: Colors.$primary;
            }
        }
        .consultation_nav-dark {
            &:hover{
                background-color:Colors.$secondary-opacity !important;
                color: Colors.$secondary !important; 
            }
        }
        .active-consultation-link {
            background-color:Colors.$primary-opacity ;
            color: Colors.$primary;
            border-left: 3px solid Colors.$primary;
            
        }
        .active-consultation-link-dark {
            background-color:Colors.$secondary-opacity ;
            color: Colors.$secondary;
            border-left: 3px solid Colors.$secondary;
            
        }
    }
    
}
.SelectControl:first-child{
color: Colors.$lightGray;
}
.toTime{ 
    background-color: Colors.$primary;
    color: #fff !important;
}

.selected {
    position: relative;
  }
  .selected::before{
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background: #000;
    bottom: 0;
    left: 40%;
    border-radius: 50%;
    background-color: Colors.$primary;
  }

.MuiPickersCalendar-week{
    margin-bottom: .41rem;
  }
  .small-side-nave{
    transition: .3s ease-in-out;
    width: 220px !important;
    @media only screen and (min-width:1024px) {
        left: -360px !important;
    }
  }